main {
	align-content: center;
	box-sizing: border-box;
	display: grid;
	font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica",
		"Arial", sans-serif;
	hyphens: auto;
	line-height: 1.65;
	margin: 0 auto;
	max-width: 680px;
	min-height: 100vh;
	padding: 72px 0;
	text-align: center;
}
h1 {
	font-size: 45px;
}
h2 {
	margin-top: 1.5em;
}
p {
	font-size: 16px;
}
a {

	color: white;
	cursor: pointer;
	text-decoration: none;
	transition: all 0.2s ease;
}
a:hover {
	transform: scale(1.1);
}
code,
pre {
	color: #d400ff;
	font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
		Bitstream Vera Sans Mono, Courier New, monospace, serif;
	font-size: 0.92em;
}
code:before,
code:after {
	content: "\`";
}

.hero-container {
	animation: moveToRight 1s ease-in-out;
}

@keyframes moveToRight {
	0% {
		transform: translateY(300px);
		opacity: 0;
	}
	100% {
		transform: translateY(0px);
	}
}

.hero-cards {
	display: flex;
	justify-content:center;
	align-items:center;
	flex-wrap: wrap;
}

.hero-card {
	width: 33%;
	border-radius: 0.5rem;
	border: solid white 0.2rem;
	margin: 2rem;
	width: auto;
	padding: 2rem;
}